// import React from "react";
// import TagManager from "react-gtm-module";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Homepage from "./pages/Homepage";

// const App = () => {
//   // Initialize Google Tag Manager
//   const tagManagerArgs = {
//     gtmId: "GTM-N3GGF5WN",
//   };
//   TagManager.initialize(tagManagerArgs);
  

//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<Homepage />} />
//       </Routes>
//     </BrowserRouter>
//   );
// };

// export default App;

import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import ThankYouPage from "./pages/ThankYouSection";
import HomepageBE from "./pages/HomepageBE";

const App = () => {
  // Initialize Google Tag Manager
  const tagManagerArgs = {
    gtmId: "GTM-N3GGF5WN",
  };
  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    const callChateleonCustom = () => {
      if (window.chateleonCustom) {
        window.chateleonCustom(process.env.REACT_APP_CHATELEONCUSTOM);//production 
        // window.chateleonCustom("nr6BGtlgWD9x7FcYJrHJJ7hqrQU6JwGuaaHeWlhF");//Localhost:3000
      }
    };

    const loadCustomScript = (src, id, callback) => {
      if (document.getElementById(id)) return;
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        callback();
      };
      document.body.appendChild(script);
    };

    const loadScript = (src, id) => {
      if (document.getElementById(id)) return;
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        console.log("SDK Loaded")
        loadCustomScript(
          "https://integration-kit.chateleon.com/v0/ai-readiness-random-walk/custom.js",
          "chateleon-custom-script",
          callChateleonCustom
        );
      };
      document.body.appendChild(script);
    };

    loadScript(
      "https://integration-kit.chateleon.com/v0/sdk.js",
      "chateleon-sdk"
    );
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/thankyou" element={<ThankYouPage/>} />
        <Route path="/business-enquiry" element={<HomepageBE />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

